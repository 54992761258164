import { Flex, Text } from '@chakra-ui/react';

const Pending = () => {
    return (
        <Flex align='center' justify='center' height='100vh'>
            <Text fontSize='3xl' as='b' textAlign='center'>Your account is on pending status, please wait for approval.</Text>
        </Flex>
    );
}

export default Pending;