import {
  useDisclosure,
  Box,
  Flex,
  HStack,
  Input,
  Select,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Icon,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  FormControl,
  FormLabel,
  ModalCloseButton,
  ModalFooter,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
} from '@chakra-ui/react';
import { Search2Icon, RepeatIcon } from '@chakra-ui/icons';
import { FiMoreVertical } from 'react-icons/fi';
import { IoCloseCircleSharp, IoCheckmarkCircleSharp } from 'react-icons/io5';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Device = () => {
  interface DeviceLocationInfoLog {
    latitude: string;
    longitude: string;
    temperature: string;
    humidity: string;
    battery: string;
    createdAt: string;
  }

  interface DeviceLocationInfoEvent {
    id: string;
    title: string;
    description: string;
    type: string;
    resolved: boolean;
    resolvedAt: null;
    createdAt: string;
  }

  interface DeviceLocationInfo {
    identifier: number;
    name: string;
    type: string;
    isPower: boolean;
    isCheated: boolean;
    isBanned: boolean;
    createdAt: string;
    log: DeviceLocationInfoLog[];
    event: DeviceLocationInfoEvent[];
  }

  interface DeviceData {
    location: DeviceLocationInfo[];
  }

  interface adminDeviceData {
    type: string;
    serial: number;
  }

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [deviceData, setDeviceData] = useState<DeviceData | null>(null);
  const [newDeviceName, setNewDeviceName] = useState('');
  const [newDeviceType, setNewDeviceType] = useState('');
  const [newDeviceSerialNo, setNewDeviceSerialNo] = useState('');
  const [searchByName, setSearchByName] = useState('');
  const [searchByType, setSearchByType] = useState('');
  const [adminDeviceActionData, setAdminDeviceActionData] =
    useState<adminDeviceData | null>(null);
  const [confirmPassword, setConfirmPassword] = useState('');

  const {
    isOpen: isCreateDeviceOpen,
    onOpen: onCreateDeviceOpen,
    onClose: onCreateDeviceClose,
  } = useDisclosure();
  const {
    isOpen: isConfirmPasswordOpen,
    onOpen: onConfirmPasswordOpen,
    onClose: onConfirmPasswordClose,
  } = useDisclosure();

  const handleQuery = () => {
    const token = localStorage.getItem('token');
    const config = { headers: { Authorization: `Bearer ${token}` } };

    if (!token) {
      navigate('/');
    }

    axios
      .get(`${process.env.REACT_APP_API_LINK}/device/latest`, config)
      .then((response) => {
        setDeviceData(response.data);
        console.log(response.data);
      })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        if (err.status === 401) {
          navigate('/pending');
        }
      });
  };

  const createNewDevice = () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      method: 'POST',
    };
    const body = {
      name: newDeviceName,
      type: newDeviceType,
      serial: newDeviceSerialNo,
    };

    axios
      .post(`${process.env.REACT_APP_API_LINK}/device/create`, body, config)
      .then((response) => {
        alert('Device added successfully.');
        setNewDeviceName('');
        setNewDeviceType('');
        setNewDeviceSerialNo('');
        onCreateDeviceClose();
        handleQuery();
      });
  };

  const submitAdminAction = () => {
    const path =
      adminDeviceActionData?.type === 'disalarm'
        ? `resolve?id=${adminDeviceActionData?.serial}`
        : `status?id=${adminDeviceActionData?.serial}&status=${adminDeviceActionData?.type}`;
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      method: 'POST',
    };
    const body = { password: confirmPassword };

    axios
      .post(`${process.env.REACT_APP_API_LINK}/device/${path}`, body, config)
      .then((response) => {
        alert('Device modified successfully.');
        setConfirmPassword('');
        onConfirmPasswordClose();
        handleQuery();
      });
  };

  const convertDate = (dateString: string) => {
    const utcDate = new Date(dateString);

    return utcDate.toLocaleString();
  };

  useEffect(() => {
    setLoading(true);
    handleQuery();
  }, []);

  const renderDeviceData = deviceData?.location.map(
    (info: DeviceLocationInfo) => {
      return (
        <Tr key={info.identifier}>
          <Td>
            <Menu>
              <MenuButton as={Button}>
                <Icon as={FiMoreVertical} />
              </MenuButton>
              <MenuList>
                <MenuGroup title='View Device'>
                  <MenuItem
                    onClick={() => {
                      setAdminDeviceActionData({
                        type: 'ban',
                        serial: info.identifier,
                      });
                      onConfirmPasswordOpen();
                    }}
                  >
                    Ban Device
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setAdminDeviceActionData({
                        type: 'unban',
                        serial: info.identifier,
                      });
                      onConfirmPasswordOpen();
                    }}
                  >
                    Unban Device
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setAdminDeviceActionData({
                        type: 'disalarm',
                        serial: info.identifier,
                      });
                      onConfirmPasswordOpen();
                    }}
                  >
                    Disalarm Device
                  </MenuItem>
                </MenuGroup>
              </MenuList>
            </Menu>
          </Td>
          <Td>
            {!info.isCheated ? (
              <></>
            ) : (
              <Icon as={IoCloseCircleSharp} w={8} h={8} color='#FF0000' />
            )}
          </Td>
          <Td>{info.name}</Td>
          <Td>{info.type}</Td>
          <Td>{info.identifier}</Td>
          <Td>{info.isBanned ? 'BANNED' : 'NORMAL'}</Td>
          <Td>{info.log[0]?.temperature}</Td>
          <Td>
            {info.isPower ? (
              <Icon as={IoCheckmarkCircleSharp} w={8} h={8} color='#15C248' />
            ) : (
              <Icon as={IoCloseCircleSharp} w={8} h={8} color='#FF0000' />
            )}
          </Td>
          <Td>{convertDate(info.log[0]?.createdAt)}</Td>
          <Td>{convertDate(info?.createdAt)}</Td>
        </Tr>
      );
    }
  );

  return (
    <Box bg='gray.100' p={4} minH='100vh'>
      <Flex justifyContent='space-between' alignItems='center'>
        <Text fontSize='18pt' fontWeight='bold' ml='5px'>
          DEVICE
        </Text>
        <Button
          colorScheme='cyan'
          color='white'
          left={1}
          mr='5px'
          onClick={onCreateDeviceOpen}
        >
          Create
        </Button>
      </Flex>

      <Modal
        blockScrollOnMount={false}
        isOpen={isCreateDeviceOpen}
        onClose={onCreateDeviceClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create New Device</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Device Name</FormLabel>
              <Input
                placeholder='Device Name'
                value={newDeviceName}
                onChange={(e) => setNewDeviceName(e.target.value)}
              />
            </FormControl>
            <br />
            <FormControl>
              <FormLabel>Device Type</FormLabel>
              <Input
                placeholder='Device Type'
                value={newDeviceType}
                onChange={(e) => setNewDeviceType(e.target.value)}
              />
            </FormControl>
            <br />
            <FormControl>
              <FormLabel>Serial Number</FormLabel>
              <Input
                placeholder='Serial Number'
                value={newDeviceSerialNo}
                onChange={(e) => setNewDeviceSerialNo(e.target.value)}
              />
            </FormControl>
            <br />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={createNewDevice}>
              Save
            </Button>
            <Button onClick={onCreateDeviceClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        blockScrollOnMount={false}
        isOpen={isConfirmPasswordOpen}
        onClose={onConfirmPasswordClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Password for Admin's Action</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Password</FormLabel>
              <Input
                placeholder='Password'
                type='password'
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </FormControl>
            <br />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={submitAdminAction}>
              Submit
            </Button>
            <Button onClick={onConfirmPasswordClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <br />
      <Box p={6} bg='white' borderRadius='md' boxShadow='md'>
        <Flex
          mb={4}
          justify='space-between'
          justifyContent='flex-end'
          align='center'
        >
          <HStack spacing={4}>
            <Input
              placeholder='Search by Name'
              value={searchByName}
              onChange={(e) => setSearchByName(e.target.value)}
              w='auto'
            />
            <Input
              placeholder='Search by Type'
              value={searchByType}
              onChange={(e) => setSearchByType(e.target.value)}
              w='auto'
            />
            {/*
                        <Select placeholder='Country Code' w='auto'>
                            <option value='TH'>Thailand</option>
                            <option value='US'>United States</option>
                        </Select>
                        */}
            <IconButton
              icon={<Search2Icon />}
              bg='transparent'
              aria-label='Search'
            />
            <IconButton
              icon={<RepeatIcon />}
              bg='transparent'
              aria-label='Refresh'
              onClick={handleQuery}
            />
          </HStack>
        </Flex>
        <Table variant='simple'>
          <Thead>
            <Tr>
              <Th>Action</Th>
              <Th>Cheat Alarm</Th>
              <Th>Name</Th>
              <Th>Type</Th>
              <Th>Serial Number</Th>
              <Th>Ban Status</Th>
              <Th>Real Time Temp</Th>
              <Th>Power Status</Th>
              <Th>Last Active On</Th>
              <Th>Created On</Th>
            </Tr>
          </Thead>
          <Tbody>{renderDeviceData}</Tbody>
        </Table>
        <Flex mt={4} justify='space-between' align='center'>
          <Text>Rows per page:</Text>
          <Select w='70px' size='sm' defaultValue='10'>
            <option value='5'>5</option>
            <option value='10'>10</option>
            <option value='20'>20</option>
          </Select>
          <Text>1-2 of 2</Text>
        </Flex>
      </Box>
      <Text align='center' color='gray.500' mt={10}>
        © 2024. All rights reserved.
      </Text>
    </Box>
  );
};

export default Device;
