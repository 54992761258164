import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import Login from './pages/login';
import DashboardNavbar from './components/DashboardNavbar';
import Dashboard from './pages/dashboard';
import Device from './pages/device';
import './App.css';

import { extendTheme } from '@chakra-ui/react';
import '@fontsource-variable/montserrat';
import '@fontsource/prompt';
import SignUp from './pages/signup';
import Pending from './pages/pending';
import ManageUser from './pages/manageuser';

const theme = extendTheme({
  fonts: {
    heading: `Montserrat Variable, Prompt, sans-serif`,
    body: `Montserrat Variable, Prompt, sans-serif`,
  },
});

function RouteList() 
{
  const location = useLocation();
  const isNotAddUser = location.pathname !== '/adduser'

  return (
    <>
      {isNotAddUser && (location.pathname !== '/' && location.pathname !== '/signup' && location.pathname !== '/pending') ? <DashboardNavbar/> : <></>}
      <Routes>
        <Route path='/' element={<Login/>} />
        <Route path='/signup' element={<SignUp/>} />
        <Route path='/dashboard' element={<Dashboard/>} />
        <Route path='/device' element={<Device/>} />
        <Route path='/manageuser' element={<ManageUser/>} />
        <Route path='/pending' element={<Pending/>} />
      </Routes>
    </>
  );
}

function App() 
{
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <RouteList />
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
