import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  CloseButton,
  Heading,
  Input,
  Link,
  Stack,
  Text,
  VStack,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { FormEvent, useState } from 'react';
import axios from 'axios';
import React from 'react';

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isMissingData, setIsMissingData] = useState(false);

  const handlePasswordVisibility = () => setShowPassword(!showPassword);

  const proceedLogin = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!email || !password) {
      setIsMissingData(true);
    } else {
      axios
        .post(`${process.env.REACT_APP_API_LINK}/auth/login`, {
          email,
          password,
        })
        .then((response) => {
          localStorage.setItem('token', response.data.token);
          // alert(`Login successful`);
          navigate('/dashboard');
        });
    }
  };

  return (
    <>
      <Alert status='error' hidden={!isMissingData}>
        <AlertIcon />
        <AlertTitle>Invalid Email or Password.</AlertTitle>
        <CloseButton
          alignSelf='flex-start'
          position='relative'
          onClick={() => setIsMissingData(false)}
        />
      </Alert>
      <Box
        minH='100vh'
        display='flex'
        justifyContent='center'
        alignItems='center'
        bg='gray.50'
      >
        <Box
          bg='white'
          p={8}
          borderRadius='md'
          boxShadow='lg'
          maxW='sm'
          w='full'
          textAlign='center'
        >
          <form onSubmit={(event) => proceedLogin(event)}>
            <VStack spacing={4}>
              <Heading as='h1' fontSize='2xl'>
                Welcome
              </Heading>
              <Text fontSize='md' color='gray.600'>
                Sign in to your account
              </Text>
              <InputGroup>
                <Input
                  placeholder='Email'
                  name='login-email'
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </InputGroup>
              <InputGroup>
                <Input
                  type={showPassword ? 'text' : 'password'}
                  name='login-password'
                  placeholder='Password'
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
                <InputRightElement width='4.5rem'>
                  <Button
                    bg='transparent'
                    h='1.75rem'
                    size='sm'
                    onClick={handlePasswordVisibility}
                  >
                    {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
              <Button colorScheme='blue' w='full' type='submit'>
                Sign In
              </Button>
            </VStack>
          </form>
          <Stack
            direction='row'
            justify='space-between'
            mt={4}
            fontSize='sm'
          >
            <Link color='blue.500' href='/signup'>Don't have an account?</Link>
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default Login;
